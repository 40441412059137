<!-- =========================================================================================
  File Name: ECommerceWishList.vue
  Description: eCommerce Wish List Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="ecommerce-wishlist-demo">
    <div class="items-grid-view vx-row match-height" v-if="serviceItems.length">
      <div :key="item.objectID" class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full" v-for="item in serviceItems">

        <item-grid-view :item="item">
          <div class="flex flex-wrap">

          </div>
        </item-grid-view>

      </div>
    </div>

  </div>
</template>

<script>
const ItemGridView = () => import('../components/services/ServiceItemDetailView');

export default {
  components: {
    ItemGridView,
  },
  data() {
    return {
      serviceItems() {
        return [];
      },
    }
  },
  mounted() {

    this.$http.get(`services`)
      .then(response => {

        if (response.status === 200) {
          const services = response.data.data;

          this.serviceItems = services
            .filter(service => !service.internal)
            .map(service => {
              return {
                name: service.name,
                redirect: service.uris.landing,
                image: service.images.services,
              };
            });

        }

      })
      .catch(e => {

      })
    ;

  },
};
</script>

<style lang="scss" scoped>
#ecommerce-wishlist-demo {
  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}
</style>
